<template>
  <div class="my-change-means">
    <div class="means-list">
      <div class="mean-items" v-for="(t, i) in showMeansList" :key="t.id">
        <span class="one-txt-cut">{{ t.title }}{{ t.num }}{{ t.unit }}</span>
        <img
          src="../assets/image/task/icon3.png"
          @click="delMeanItem(i, t.id)"
        />
      </div>
      <div class="add-btn" @click="addFormMeans" v-if="isAddBtnShow">
        <img src="../assets/image/task/icon1.png" /><span>添加</span>
      </div>
    </div>
    <div class="means-change" v-show="isFormMeanShow">
      <el-select
        v-model="meansType"
        placeholder="请选择分类"
        @change="getResourceList"
      >
        <el-option
          :label="item.name"
          :value="item.id"
          v-for="item in meanClassList"
          :key="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="meansItmes"
        placeholder="请选择农资"
        :disabled="disabled"
        @change="changeMeansItems"
      >
        <el-option
          :label="item.title"
          :value="item.title"
          v-for="item in meansOptionList"
          :key="item.id"
        ></el-option>
      </el-select>
      <el-input
        class="num"
        v-model.trim="meansItmesNum"
        maxlength="20"
        autocomplete="off"
      ></el-input>
      <span class="txt">{{ unit }}</span>
      <img
        class="icon2-imgs"
        src="../assets/image/task/icon2.png"
        @click="addDetermine"
      />
    </div>
  </div>
</template>

<script>
import { getResourceClassListApi, getResourceListApi } from "@/request/api";
import { mapState } from "vuex";

export default {
  props: {
    resource: {
      type: Array,
    },
    isAddBtnShow: {
      type: Boolean,
    },
    addShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      meanClassList: [],
      meansOptionList: [],
      meansList: {},
      showMeansList: [],
      unit: "",
      meansId: "",
      meansType: "",
      meansItmes: "",
      meansItmesNum: "",
      disabled: false,
      isFormMeanShow: false,
    };
  },
  watch: {
    meansType: {
      handler(n) {
        if (n) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
      immediate: true,
    },
    resource: {
      handler(newVal) {
        if (!newVal.length) {
          this.meansList = {};
          this.showMeansList = [];
        }
      },
      deep: true,
    },
    addShow: {
      handler(newVal) {
        if (newVal) {
          this.initListData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
    }),
  },
  created() {
    this.getResourceClassList();
  },
  methods: {
    initListData() {
      if (Array.isArray(this.resource)) {
        this.showMeansList = [...this.resource] || [];
        if (this.showMeansList.length) {
          this.showMeansList.forEach((item) => {
            this.meansList[item.id] = item.num;
          });
        }
      }
    },
    changeMeansItems(e) {
      let unitIndex = this.meansOptionList.findIndex((item) => item.title == e);
      this.unit = this.meansOptionList[unitIndex].unit;
      this.meansId = this.meansOptionList[unitIndex].id;
    },
    async getResourceList() {
      const { code, results } = await getResourceListApi({
        manor_id: this.farmID,
        cid: this.meansType,
      });
      if (code === 0) {
        this.meansOptionList = results.data;
      }
    },
    async getResourceClassList() {
      const { code, results } = await getResourceClassListApi({
        manor_id: this.farmID,
      });
      if (code === 0) {
        this.meanClassList = results.data;
      }
    },
    delMeanItem(index, id) {
      Reflect.deleteProperty(this.meansList, id);
      this.showMeansList.splice(index, 1);
      this.$emit("getMeansList", this.meansList);
    },
    addFormMeans() {
      this.isFormMeanShow = true;
    },
    addDetermine() {
      if (!this.meansType || !this.meansItmesNum || !this.meansItmes) {
        this.$notify.error({
          title: "错误",
          message: "请填写内容",
        });
        return;
      }
      this.showMeansList.push({
        id: this.meansId,
        title: this.meansItmes,
        num: this.meansItmesNum,
        unit: this.unit,
      });
      this.meansList[this.meansId] = +this.meansItmesNum;
      this.isFormMeanShow = false;
      this.meansType = "";
      this.meansItmes = "";
      this.meansItmesNum = "";
      this.$emit("getMeansList", this.meansList);
    },
  },
};
</script>

<style lang="less">
.my-change-means {
  .means-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .mean-items {
      height: 38px;
      background: #081c30;
      border-radius: 3px;
      margin-right: 19px;
      margin-bottom: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #3e90e5;
      padding: 0 10px;

      &:hover {
        opacity: 0.8;
      }
      img {
        width: 10px;
        height: 10px;
        margin-left: 9px;
        cursor: pointer;
      }
      span {
        display: block;
        width: calc(100% - 10px);
      }
    }

    .add-btn {
      width: 99px;
      height: 40px;
      background: #3e90e5;
      border-radius: 3px;
      display: flex;
      align-items: center;
      margin-bottom: 19px;
      justify-content: center;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      img {
        width: 21px;
        margin-right: 6px;
      }

      span {
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .means-change {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;

    .txt {
      margin: 0 9px;
      font-size: 14px;
      color: #57708d;
    }

    .icon2-imgs {
      width: 29px;
      height: 29px;
      cursor: pointer;
    }

    .el-select {
      width: 120px !important;
      margin-right: 14px;

      .el-input {
        width: 100% !important;
        .el-input__inner {
          width: 120px !important;
        }
      }
    }

    .num.el-input {
      width: 86px !important;
      margin-right: 9px;

      .el-input__inner {
        width: 86px !important;
      }
    }
  }
}
</style>
